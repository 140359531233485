import {PatientChangeNotifier} from './PatientChangeNotifier';
import {ICDService} from './ICDService';
import {IRiskItem} from "../classes/IRiskItem";
import * as Fhir from "resources/classes/FhirModules/Fhir";
import {CIRiskAssessment, QuestionnaireResponse} from "resources/classes/FhirModules/Fhir";
import {I18N} from 'aurelia-i18n';
import {IQuestionnaireList, QuestionnaireService} from './QuestionnaireService';
import {UserService} from "./UserService";
import {FhirService} from "./FhirService";
import {AnalyzeService} from './analyzeService';
import {ConfigService} from "./ConfigService";
import {PatientItem} from "../classes/Patient/PatientItem";
import {IFormSetting} from "../classes/IFormSettings";
import {NitTools} from "../classes/NursitTools";
import {SpiSpiderSvg} from "../classes/SpiSpiderSvg";
import {PatientService} from "./PatientService";
import {DialogService} from "aurelia-dialog";
import {isNaN} from "lodash";
import {fhirEnums} from "../classes/fhir-enums";
import HTTPVerb = fhirEnums.HTTPVerb;
import BundleType = fhirEnums.BundleType;
import {DialogMessages} from "./DialogMessages";

/** This is the base class to be used for all Analyzer-Versions.
 * always inherit a new Analyzer from this class and use ,inject() to register it with the AnalyzeService
 */
export class AnalyzerClass {
    _version: string = "BASIC";
    icdService: ICDService;
    i18n: I18N;
    _resultHtml: string;
    public get resultHtml() : string {
        return this._resultHtml;
    }

    patientChangeNotifier: PatientChangeNotifier;
    userService: UserService;
    fhirService: FhirService;
    lastIcdUpdateTime: Date;
    isUpdatingBundle: boolean;
    description: string = "This is the basic analyzer description"
    assessmentName: string = 'CareITAssessment';
    anamnesisName: string = 'CareITAnamnesis';
    analyserConfig: IFormSetting;
    riskFieldsSetting: IRiskFieldsSetting;
    needToStoreFlags: boolean = false;
    protected formSettingAssessment : IFormSetting;
    protected formSettingAnamnesis : IFormSetting;
    protected formSettingFIM : IFormSetting;
    protected formSettingBI : IFormSetting;
    protected formSettingBIEx : IFormSetting;
    protected pageSettings : IPageSetting[];

    constructor(version: string, icdService: ICDService, i18n: I18N, patientChangeNotifier: PatientChangeNotifier, userService: UserService,
                protected dialogService: DialogService) {
        this._version = version;
        this.fhirService = new FhirService();
        this.icdService = icdService;
        this.i18n = i18n;
        this.patientChangeNotifier = patientChangeNotifier;
        this.userService = userService;
    }

    public async DisplayRiskPage(page : number) : Promise<SVGImageElement> {
        return undefined;
    }

    public GetRiskSun(patient : PatientItem) : Promise<SVGImageElement> {
        return undefined;
    }

    public getNCSSpiderTextHtml(patient : PatientItem) : string {
        return '<span></span>';
    }

    public GetSPISpider(patient : PatientItem) : Promise<SVGImageElement> {
        return undefined;
    }

    public GetSPISpider2(patient : PatientItem) : Promise<SVGImageElement> {
        return undefined;
    }

    protected findRisk(patient : PatientItem, risk : string) {
        if (!patient || !risk) return undefined;
        const result = patient?.currentRisks?.prediction?.find(o=>o.outcome?.coding?.[0].system?.toUpperCase().endsWith(risk.toUpperCase()));
        return result?.outcome?.coding?.[0];
    }

    /***
     * Checks if a linked response for the given questionnaire exists and creates one if not.
     * @param questionnaire the Questionnaire to take the Name from for seeking the attached response
     * @param patient the patient to process the QuestionnaireResponse for
     * @param linkSource where to get or set the response link extension
     * @protected
     */
    protected async ensureResponse(questionnaire: any, patient: PatientItem, linkSource: any): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                let response = await Fhir.QuestionnaireResponse.GetAttachedResponse(patient, linkSource, questionnaire.name);
                if (response) {
                    resolve(response);

                    return;
                }

                response = Fhir.Tools.SubstituteDefaultQRSkeleton(patient, questionnaire.id, 'in-progress');
                Fhir.QuestionnaireResponse.SetAttachedResponse(patient, linkSource, response);
                Fhir.QuestionnaireResponse.SetAttachedResponse(patient, response, linkSource);

                await Fhir.Rest.Bundle([linkSource, response], HTTPVerb.put, BundleType.transaction);

                resolve(response);
            }
            catch (e) {
                console.warn(e.message || e);
                reject(e.message||e)
            }
        })
    }

    /*** gets the current value of responseItem (iE bi01Answer),
     * from the questionnaire item option ordinalValue */
    protected  getCurrentValue(questionnaire : any, responseItem : any) {
        try {
            const linkId = responseItem.linkId;
            if (!responseItem || !responseItem.answer || !responseItem.answer[0]
                || !responseItem.answer[0].valueCoding || !responseItem.answer[0].valueCoding.code)
                return NaN;

            const value = responseItem.answer[0].valueCoding.code;
            const questionnaireItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(questionnaire, linkId);
            if (!questionnaireItem || !questionnaireItem.option) return NaN;
            const option = questionnaireItem.option.find(o => o.valueCoding && o.valueCoding.code === value);
            if (!option) return NaN;

            const ext = option.extension.find(o => o.url && o.url.endsWith('/questionnaire-ordinalValue'));
            return (ext && typeof ext.valueDecimal === "number") ? ext.valueDecimal : NaN;
        }
        catch (e) {
            console.warn(e.message||e);
            return NaN;
        }
    }

    public inject() {
        if (!this._version || this._version === "BASIC") {
            console.error("This is the Base-Class for Analyzers. Please inherit a new class with this as base class to inject another Analyzer-Version");
            return;
        }

        if (typeof AnalyzeService.Analyzers === "undefined") AnalyzeService.Analyzers = {};
        AnalyzeService.Analyzers[this._version.toUpperCase()] = this;
    }

    protected makeTwo(num: number): string {
        let s = String(num);
        if (s.length < 2) {
            s = "0" + s;
        }

        return s;
    }

    protected FormSum(sum: number): number {
        if (typeof sum !== "number")
            sum = 0;
        if (sum < 0)
            sum = 0;
        if (isNaN(sum))
            sum = 0;

        return sum;
    }

    protected getCalcSum(response: any, fields: string[], continueIfNaN: boolean): number {
        let sum = 0;
        for (let i = 0; i < fields.length; i++) {
            let iitem = Fhir.QuestionnaireResponse.GetResponseItemByLinkId(response, fields[i], false);
            if (iitem) {
                let itemValue = String(Fhir.QuestionnaireResponse.GetResponseItemValue(iitem));

                //  QuestionnaireResponseItem(iitem);
                if ((itemValue === "" || itemValue.toUpperCase().endsWith("_NAN")) && !continueIfNaN) {
                    // item found, but no value and don't ignore missing values:
                    if (ConfigService.Debug) {
                        console.warn(`Field "${fields[i]}" contained a NaN Value ("${itemValue}"), exiting returning NaN!`)
                    }

                    return NaN;
                } else {
                    if (itemValue.indexOf('_') > -1) {
                        let sa = itemValue.split('_');
                        itemValue = sa[sa.length - 1];
                    }

                    if (/\D+/gi.test(itemValue)) console.warn(`NOT A VALID NUMBER: "${itemValue}" in field "${fields[i]}"`, iitem)

                    let fValue = NaN;
                    try {
                        fValue = parseFloat(itemValue);
                    }
                    catch (e) {
                        console.warn(e.message||e);
                    }

                    if (typeof fValue == "number" && !isNaN(fValue))
                        sum += fValue;
                }
            } else {
                // no item found, and don't ignore missing values:
                if (!continueIfNaN)
                    return NaN;
            }
        }

        return sum;
    }

    updateRiskAssessmentPrediction(calculationResult, patient: PatientItem, value?: string): AnalyzerClass {
        if (!patient || !patient.currentRisks) return this;
        if (calculationResult["linkIdIsRisk"]) {
            let predictionRisk = CIRiskAssessment.GetPredictionCoding(patient.currentRisks, calculationResult.linkIdIsRisk);
            if (predictionRisk) {
                predictionRisk.code = String(value || calculationResult.isRisk);
                predictionRisk.display = calculationResult.riskText || this.i18n.tr(calculationResult.isRisk ? "yes" : "no");
            } else {
                predictionRisk = CIRiskAssessment.CreateRiskAssessmentPrediction(calculationResult.linkIdIsRisk, String(value || calculationResult.isRisk), calculationResult.riskText || this.i18n.tr(calculationResult.isRisk ? "yes" : "no"));
                (<any[]>patient.currentRisks.prediction).push(predictionRisk);
            }
        }

        let sumLink = calculationResult.linkIdSum || calculationResult.field;
        if (sumLink) {
            let code = String(value || calculationResult.sum);
            let display = String(calculationResult.textSum || calculationResult.text);
            let predictionSum = CIRiskAssessment.GetPredictionCoding(patient.currentRisks, sumLink);
            if (!predictionSum) {
                predictionSum = CIRiskAssessment.CreateRiskAssessmentPrediction(sumLink, code, display);
                if (predictionSum)
                    (<any[]>patient.currentRisks.prediction).push(predictionSum);
            } else {
                predictionSum.code = code;
                predictionSum.display = display;
            }
        }

        return this;
    }

    updateRiskAssessmentPredictionFromSimpleResult(calculationResult, patient: PatientItem, createSum: boolean = true): AnalyzerClass {
        return this.updateRiskAssessmentPrediction({
                linkIdIsRisk: calculationResult.field,
                isRisk: calculationResult.isRisk,
                linkIdSum: createSum ? calculationResult.field + "_sum" : undefined,
                sum: typeof (calculationResult.isRisk) === "undefined" ? calculationResult.sum : calculationResult.isRisk ? "1" : "0",
                textSum: calculationResult.text || String(calculationResult.sum)
            },
            patient);
    }

    ///// START IMPLEMENTATION/OVERLOADS HERE //////////////////
    protected async loadFormSetting(patient : PatientItem, route : string) {
        if (!patient || !patient.ward || !route) return undefined;
        let cfg = await ConfigService.LoadConfigOverride(patient?.ward, patient);
        if (!cfg) {
            cfg = ConfigService.ConfigOverrides["default"];
        }

        return cfg.forms.find(o=>o.route && o.route.toUpperCase() === route.toUpperCase());
    }

    public async calculateSpi(patient: PatientItem, from?: any): Promise<IRiskItem> {
        return undefined;
    }

    public calculateRiskDecu(patient: PatientItem, assessment?: any) {
        return undefined;
    }

    public calculateNRS(patient: PatientItem, assessment: any, qList: IQuestionnaireList, spi?: number) {
        return undefined;
    }

    public async calculateMNA(patient: PatientItem, assessment: any) {
        return undefined;
    }

    public calculateBarthelIndex(patient: PatientItem, assessment: any, biResponse: any): number {
        return undefined;
    }

    public calculateBartheldIndexEx(patient: PatientItem, assessment: any, anamnesis: any, biExResponse: any): number {
        return undefined;
    }

    public calculateFall(patient: PatientItem, assessment?: any) {
        return undefined;
    }

    public calculatePneumo(patient: PatientItem, assessment?: any) {
        return undefined;
    }

    public calculateIncontinenceProfile(patient: PatientItem, assessment?: any) {
        return undefined;
    }

    public calculateVdd(patient: PatientItem, assessment?: any) {
        return undefined;
    }

    public async updateRiskAssessmentValues(patient: PatientItem, assessmentResponse: any, riskAssessment: any) {
        if (!this.riskFieldsSetting || !assessmentResponse || ['completed', 'amended'].indexOf(assessmentResponse.status) === -1 || !riskAssessment) {
            return;
        }

        const assessment = QuestionnaireService.GetQuestionnaireDirect(assessmentResponse?.questionnaire);
        const anamneseName = ConfigService.GetFormSettings('anamnesis').questionnaireName;
        const anamnese = QuestionnaireService.GetQuestionnaireByNameDirect(anamneseName);
        const latestAnamnesis = QuestionnaireService.GetLatestResponseOfType(patient, anamnese.id);

        const keys = Object.keys(this.riskFieldsSetting).filter(o => o.indexOf('risk_') > -1);
        // console.warn('updateRiskAssessmentValues', keys);
        for (const key of keys) {
            const linkId = this.riskFieldsSetting[key];

            if (linkId) { // the linkId may not be given. Maybe it is an epa2.2 field that does not exist in 2.3
                let responseItem = QuestionnaireResponse.GetResponseItemByLinkId(assessmentResponse, linkId);
                if (!responseItem)   // maybe it is a source field from anamnesis, so try to get the value from there
                    responseItem = QuestionnaireResponse.GetResponseItemValueByLinkId(latestAnamnesis, linkId);

                if (!responseItem) console.warn('No Item with linkId ' + linkId + ' (key="' + key + '") found in Anamnesis or Assessment');

                if (responseItem && responseItem.answer && responseItem.answer.length >= 1) {
                    const answer = responseItem.answer[0];
                    if (answer && answer.valueCoding && answer.valueCoding.code && answer.valueCoding.display) {
                        const value = String(answer.valueCoding.display);
                        let outComeCoding: any = undefined;
                        if (value) {
                            for (const pred of riskAssessment.prediction) {
                                if (pred && pred.outcome) {
                                    for (const coding of pred.outcome.coding) {
                                        if (coding.system && coding.system.endsWith(key)) {
                                            outComeCoding = coding;
                                            break;
                                        }
                                    }
                                }

                                if (outComeCoding) break;
                            }

                            if (!outComeCoding) {
                                outComeCoding = {
                                    system: "http://nursit-institute.com/" + key,
                                    display: answer.valueCoding.display,
                                    code: answer.valueCoding.code
                                }

                                const newPrediction: any = {
                                    outcome: {
                                        coding: [outComeCoding]
                                    },
                                    whenPeriod: {
                                        start: assessmentResponse.authored
                                    }
                                }

                                riskAssessment.prediction.push(newPrediction);

                                // console.debug('Added new any "' + key + '" to ', riskAssessment);
                            } else {
                                outComeCoding.display = answer.valueCoding.code;
                                outComeCoding.code = answer.valueCoding.display;
                            }

                            // this seems to be a value like 05_01_03, so get the ordinal value from questionnaire
                            if (outComeCoding.code && /[A-Za-z_]/g.test(outComeCoding.code)) {
                                if (assessment) {
                                    const sourceItem = Fhir.Questionnaire.GetQuestionnaireItemByLinkId(assessment, linkId);
                                    if (sourceItem && sourceItem.option) {
                                        let corrected = false;
                                        for (const option of sourceItem.option.filter(o => o.extension)) {
                                            if (option.valueCoding && option.valueCoding.code === outComeCoding.code) {
                                                const ordValueExtentsion = option.extension.find(o => o.url.endsWith('questionnaire-ordinalValue'));
                                                if (ordValueExtentsion) {
                                                    const intVal = String(ordValueExtentsion.valueDecimal);
                                                    if (!isNaN(parseInt(intVal))) {
                                                        outComeCoding.code = intVal;
                                                        corrected = true;
                                                    }
                                                }
                                            }

                                            if (corrected)
                                                break;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        // const spiImage = await SpiSpiderSvg.Create(patient, this.i18n);


        PatientService.AddRiskAssessment(patient, riskAssessment);

        /* if (ConfigService.Debug)
            console.debug('Updated RiskAssessment-Values', riskAssessment); */
    }

    /**
     * Refreshes the Analyze-Values for the given patient
     * @param patient - the patient to analyze
     * @param assessment - the assessment to use for analysis
     * @param updateRiskAssessment - a value indicating whether the RiskAssessment for the patient should be created/updated. Defaults to true
     * @param storeRiskAssessment - as value indicating whether the RiskAssessment for the patient should be updated on the fhir server
     */
    public async analyse(patient: PatientItem, assessment?: any, updateRiskAssessment: boolean = true, storeRiskAssessment: boolean = true): Promise<any> {
        if (patient)
            await ConfigService.LoadConfigOverride(patient.ward, patient);

        this.analyserConfig = ConfigService.GetFormSettings('analysis');

        if (this.analyserConfig && this.analyserConfig.settings &&
            this.analyserConfig.settings.riskFields && this.analyserConfig.settings.riskFields.length > 0) {
            this.riskFieldsSetting = this.analyserConfig.settings.riskFields.find(o => (<IRiskFieldsSetting>o).analyzers.indexOf(this._version) > -1);
        }

        return Promise.resolve();
    }
}

export interface IRiskFieldsSetting {
    svgChart?: string;
    svgRisks?: string;
    analyzers: string[];
    risk_activity: string;
    risk_eat: string;
    risk_drink: string;
    risk_urine: string;
    risk_stool: string;
    risk_body_care: string;
    risk_upper_body_care: string;
    risk_lower_body_care: string;
    risk_upper_body_dress: string;
    risk_lower_body_dress: string;
    risk_knowledge: string;
}

export interface IPageSetting {
    riskName : string;
    showPage: number;
}
